.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.form_page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -50px;
  height: 100vh;
}
.signin {
  display: flex;
  height: max-content;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.title {
  font-size: 18px;
  font-weight: bold;
  display: table-cell;
  text-align: center;
}

.input_container {
  display: flex;
  flex-direction: column;
  margin: 10px 5px;
  text-align: left;
  align-items: center;
  width: 100%;
  font-size: medium;
}
input[type="text"],
input[type="password"] {
  height: 25px;
  background-color: transparent;
  color: #383838;
  outline: none;
  outline-style: none;
  width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #adb5bd;
}
.error {
  color: red;
  font-size: 12px;
}
.button_container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.signin_container{
  display: flex;
  justify-content: right;
  margin-top: 20px;
}
.member_span {
  font-size: 14px;
  display: flex;
  align-items:center;
}
.signup_button {
  color: #40a9ff;
  background: 0 0;
  border-color: transparent;
}
::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #555252;
	border: 0px solid #555555;
  border-radius: 10px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
