.list_content {
    position: fixed;
    top: 85px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 90px);
}
.grid_list {
    padding: 25px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    grid-auto-columns: min-content;
    overflow-y: visible;
}
@media screen and (max-height: 620px) {
    .list_content {
        top: 65px;
    }
    .content {
        overflow-y: hidden;
    }
  }
