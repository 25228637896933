.petition_main {    
    line-height: 25px;
    padding: 15px;
}
.petition_title {
    height: fit-content;
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 10px;
}  
.petition_content {
    height: calc(100vh - 200px);
    padding: 10px 20px;
    text-align: left;
    font-size: 1.1rem;
    text-align: justify;
    font-family: system-ui;
    font-weight: normal;
    overflow: auto;
}
.petition_content > p {
    height: fit-content;
}
.petition_footer > label {
    padding: 0 20px;
    margin: 0 0 15px;
}
.submitsec {
    display: inline-block;
}
.submitsec > input[type="text"] {
    width: 400px;
    font-size: 1.1rem;
    height: min-content;
    margin: 10px 10px;
}
.submitsec > input[class= "textval"] {
    border-bottom: #e54848 2px solid;
}
.submitsec > .submitbtn {
    height: min-content;
    min-width: 100px;
    font-size: 1.1rem;
    border-radius: 10px;
    border: 1px solid;
    border-style: groove;
    margin: 10px 10px 0px;
}
.petition_footer {
    position: fixed;
    left: 1%;   
    width: 98%;
    text-align: center;
    height: fit-content;
    bottom: calc(10vh - 70px);;
    border-radius: 25px;
    border: 2px solid #fff;
}
@media screen and (max-width: 1400px) {
    .petition_content {
        height: calc(100vh - 300px);
        font-size: 1.1rem;
    }
    .petition_footer > label {
        font-size: 1.1rem;
    }      
    .submitsec > input[type="text"] {
        width: 330px;
        font-size: 1.1rem;
    }
    .submitsec > .submitbtn {
        font-size: 1.1rem;
    }
}
@media screen and (max-width: 1024px) {
    .petition_content {
        height: calc(100vh - 300px);
        font-size: 1rem;
    }
    .petition_footer > label {
        font-size: 1rem;
    }      
    .submitsec > input[type="text"] {
        width: 300px;
        font-size: 1rem;
    }
    .submitsec > .submitbtn {
        font-size: 1rem;
    }      
}
@media screen and (max-width: 768px) {
    .petition_title {
        font-size: 1rem;
        margin: 0;
    }
    .petition_content {
        height: calc(100vh - 380px);
        font-size: 0.9rem;
    }
    .petition_footer > label {
        font-size: 0.9rem;
    }
    .submitsec > input[type="text"] {
        width: 300px;
        font-size: 0.9rem;
    }
    .submitsec {
        display: inline-grid;
    }
    .submitsec > .submitbtn {
        font-size: 0.9rem;
    }    
}
@media screen and (max-width: 640px) {
    .petition_title {
        font-size: 0.8rem;
        margin: 0;
        font-weight: bold;
    }
    .petition_content {
        overflow-y: scroll;
        height: calc(100vh - 390px);
        font-size: 0.8rem;
    }    
    .petition_footer > label {
        font-size: 0.8rem;
    }
    .submitsec > input {
        width: 13px;
        height: 13px;
        top: -6px;
    }
    .submitsec > input[type="text"] {
        width: 250px;
        font-size: 0.8rem;
    }
    .submitsec {
        display: inline-grid;
    }
    .submitsec > .submitbtn {
        font-size: 0.8rem;
    }    
} 





