.post {
    display: flex;
    flex-direction: column;
    justify-content: top;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    margin: 0.2rem .7em .2rem .5rem;
    background: #fff;
    border-radius:  0.5rem;
    text-align: left;
    height: 90%;
    max-width: 350px;
    min-width: 150px;
    filter: drop-shadow(0 0 5px #555);;
  }
  .title {
    display: table;
    font-size: 0.6rem;
    font-weight: bold;
    color: #000;
    height: fit-content;
    width: 100%;
    text-align: center;
    margin: 12px 0px;
    padding: 4px;
  }
  .title:link,  
  .title:visited,
  .tag:link,
  .tag:visited {
    text-decoration: none;
  }
.title:hover {
    text-decoration: underline;
    color: #b31a1a;
  }
  
  .title:active {
    text-decoration: underline;
  }

  .title:before {
    content:'';
    height:100%;
    display:inline-block;
    vertical-align: middle;
}
  .postImage {
     inline-size: 100%;
     aspect-ratio: 16 / 9;
     object-fit: fill;
  }
  .imageLink {
    overflow: hidden;
    height: 140px;
  }
  .imageLink img {
    transition: transform .5s ease;
  }
  .imageLink:hover img {
    transform: scale(1.1);
  }
  .imageLink:focus img {
    transform: scale(1.1);
  }
  .imageLink:hover a {
    height: 20px;
  }
  .description {
      margin: 3px 0 3px 0;
      height: 40px;
      font-size: 0.6rem;

    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media screen and (min-height: 700px) {
  
  .description {
      height: 40px;
      font-size: 0.6rem;
  }
  .postImage {
    inline-size: 100%;
    aspect-ratio: 16 / 9;
    object-fit: fill;
 }
 .title {
 
  font-size: 0.8rem;
}
}
  @media screen and (min-height: 1000px) {
    .imageLink {
      height: 300px;
  }
  .description {
    height: 70px;
      font-size: 0.8rem;
  }
  .postImage {
    inline-size: 100%;
    aspect-ratio: 4 / 3;
    object-fit: fill;
 }
 .title {
 
  font-size: 1.1rem;
}
}
  .webtitle {
    display: block;
    padding-top: 3px;
    
  }
  .webname {
    float: left;
    font-size: 14px;
    color: #423E46;
    font-weight: bolder;
    width: 172px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .date {
    float: right;
    font-size: 12px;
    font-weight: bold;
    color: #645a5a;
  }
  .tags {
      display: flex;
      padding-top: 3px;
  }
  .tag {
    font-size: 12px;
    border: 1px solid;
    border-color: #14090b45;
    width: fit-content;
    margin-right: 2px;
    padding: 0px 4px;
    color: #7c0404;
    border-radius: 5px;
    text-align: center;
  }
  .tag:active,
  .tag:hover {
    background-color: #6a1e38de;
    color: #fff
  }
  small {
    border: 2px solid #777;
    border-radius: 7px;
    padding: 5px 12px;
    color: #777;
  }
  


.list_listTittle {
  height: 25px;
  margin: 10px;
  border-bottom: 2px solid #17a2b8;
  font-family: system-ui;
  font-weight: bold;
  font-size: 1rem;
}

.scroll-container {
  position: relative;
  margin-top: 10px;
  height: 200px;
}

@media screen and (min-height: 1080px) {
  .scroll-container {
    height: 380px;
  }
}

@media screen and (min-height: 768px) {
  .scroll-container {
    height: 300px;
  }
  .imageLink {
    height: 200px;
  }
}

@media screen and (max-width: 550px) {
  .scroll-container {
    height: 380px;
  }
  .imageLink {
    height: 200px;
  }
}

    .mediaScroller {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 18%;
      gap: var(--size-3);
      overflow: hidden;
    scroll-behavior: smooth;
  }
  .snap-inline {
    scroll-snap-type: inline mandatory;
  }
  .snap-inline > * {
    scroll-snap-align: start;
  }
